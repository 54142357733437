var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-dashboard-panel",
    {
      attrs: {
        icon: "mdi-account-multiple",
        "avatar-color": "red",
        "module-name": "connectid",
        title: "User Accounts",
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("TasksAccounts", {
                attrs: {
                  color: "background",
                  widget: "",
                  "with-border": "",
                  "toc-level": 2,
                  expandable: "",
                  "no-maximize": "",
                  meta: {
                    title: "Pending Approval",
                    dark: false,
                    statuses: ["REGISTERED", "MODIFIED"],
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("TasksAccounts", {
                attrs: {
                  color: "red",
                  light: "",
                  "toc-level": 2,
                  "with-border": "",
                  expandable: "",
                  "no-maximize": "",
                  widget: "",
                  meta: {
                    title: "Rejected Accounts",
                    statuses: ["REJECTED"],
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("TasksAccounts", {
                attrs: {
                  color: "orange",
                  light: "",
                  "toc-level": 2,
                  "with-border": "",
                  expandable: "",
                  "no-maximize": "",
                  widget: "",
                  meta: {
                    title: "Returned Accounts",
                    statuses: ["RETURNED"],
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }