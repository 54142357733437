<template>
  <asyent-dashboard-panel
    icon="mdi-account-multiple"
    avatar-color="red"
    module-name="connectid"
    title="User Accounts"
  >
    <v-row>
      <v-col cols="12">
        <TasksAccounts
          color="background"
          widget
          with-border
          :toc-level="2"
          expandable
          no-maximize
          :meta="{
            title: 'Pending Approval',
            dark: false,
            statuses: ['REGISTERED', 'MODIFIED']
          }"
        ></TasksAccounts>
      </v-col>
      <v-col cols="12">
        <TasksAccounts
          color="red"
          light
          :toc-level="2"
          with-border
          expandable
          no-maximize
          widget
          :meta="{
            title: 'Rejected Accounts',
            statuses: ['REJECTED']
          }"
        ></TasksAccounts>
      </v-col>
      <v-col cols="12">
        <TasksAccounts
          color="orange"
          light
          :toc-level="2"
          with-border
          expandable
          no-maximize
          widget
          :meta="{
            title: 'Returned Accounts',
            statuses: ['RETURNED']
          }"
        ></TasksAccounts>
      </v-col>
    </v-row>
  </asyent-dashboard-panel>
</template>

<script>
  import TasksAccounts from "@/modules/connectid/components/tasks-accounts.vue";

  export default {
    name: "AccountTasks",
    components: { TasksAccounts }
  };
</script>

<style lang="scss" scoped>
  @import "~@/scss";
  @import "~@/scss/_asyent-components.scss";
</style>
