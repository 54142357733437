var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-widget",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "header-actions": _vm.headerActions,
            title: _vm.ae$prefixLng("connectid._library", _vm.meta["title"]),
            "sub-title": _vm.ae$prefixLng(
              "connectid._library",
              "User Accounts"
            ),
            "toolbar-actions": _vm.toolbarActions,
            "toolbar-notifications": _vm.toolbarNotifications,
          },
          on: {
            "toolbar-action": function ($event) {
              return _vm.toolbarMethod($event)
            },
          },
        },
        "asyent-widget",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("asyent-search", {
        attrs: {
          "filter-dialog": _vm.filterDialog,
          filters: _vm.filters,
          headers: _vm.headers,
          "reload-counter": _vm.reloadCounter,
          "row-actions": _vm.rowActions,
          "row-method": _vm.rowMethod,
          "search-method": _vm.search,
          criteria: _vm.criteria,
          "no-height": _vm.widget,
          "export-counter": _vm.exportCounter,
          "export-file-name": "tasks-accounts",
          "no-outline": _vm.widget,
          "height-limit": "",
          "items-per-page-options": [5],
          "simple-loading": "",
        },
        on: {
          "filter-dialog": function ($event) {
            _vm.filterDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.group.name",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(item["group.name"]) +
                    " (" +
                    _vm._s(item["group.code"]) +
                    ") "
                ),
              ]
            },
          },
          {
            key: "item.loginIdentifier",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "asyent-link",
                  {
                    attrs: {
                      link:
                        "/connectid/accounts/view/" + item["loginIdentifier"],
                    },
                  },
                  [_vm._v(" " + _vm._s(item["loginIdentifier"]) + " ")]
                ),
              ]
            },
          },
          {
            key: "item.profile.firstName",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  _vm._s(
                    item["profile.firstName"]
                      ? item["profile.firstName"]
                      : "..."
                  )
                ),
              ]
            },
          },
          {
            key: "item.profile.lastName",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      item["profile.lastName"]
                        ? item["profile.lastName"]
                        : "..."
                    )
                ),
              ]
            },
          },
          {
            key: "item.status",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      color: item["status"] === "ACTIVE" ? "green" : "red",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.ae$EnumToString(item["status"])) + " "
                    ),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.documentStatus",
            fn: function (ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    attrs: {
                      color:
                        item["documentStatus"] === "MODIFIED" ||
                        item["documentStatus"] === "REGISTERED"
                          ? "yellow"
                          : item["documentStatus"] === "CREATED"
                          ? "green"
                          : "red",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.ae$EnumToString(item["documentStatus"])) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }